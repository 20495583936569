


import {
    AiFillFacebook,
} from 'react-icons/ai';
import { FaTelegram } from 'react-icons/fa';
import Link from 'next/link';
import { IoLogoWhatsapp } from 'react-icons/io';

const Footer = () => {
    return (
        <footer className="bg-[#252627] dark:bg-[#252627]/60 border-t border-gray-500/20">
            <div className='flex flex-col md:flex-row gap-6 items-center justify-between py-6 px-4 container mx-auto sm:px-6 lg:px-8'>
                <div className="flex items-center justify-center gap-4 transition-colors-opacity duration-300">
                    <p className='text-white dark:text-foreground p-0 m-0 text-sm'>Follow us</p>
                    <Link href="https://www.facebook.com/groups/1719606045143084" aria-label="Join Our Facebook Group" target='_blank'>
                        <AiFillFacebook className="cursor-pointer text-white dark:text-foreground hover:text-[#1877f2] bg-gray-700/20 hover:bg-gray-700/50 p-2 rounded-full" size={35} />

                    </Link>
                    <Link href="https://t.me/+ywwLx_GiaAI5NTRl" aria-label="Join Our Telegram Group" target='_blank'>
                        <FaTelegram className="cursor-pointer text-white dark:text-foreground hover:text-[#0088cc] bg-gray-700/20 hover:bg-gray-700/50 p-2 rounded-full" size={35} />

                    </Link>
                    <Link href="https://chat.whatsapp.com/HKrVbMTI7QUFBydF5qCDPu" aria-label="Join Our Whatsapp Group" target='_blank'>
                        <IoLogoWhatsapp className="cursor-pointer text-white dark:text-foreground hover:text-[#128c7e] bg-gray-700/20 hover:bg-gray-700/50 p-2 rounded-full" size={35} />
                    </Link>
                </div>
                <div className="flex flex-col md:flex-row gap-5 items-center justify-center">
                    <Link href="/about-us" className="text-white dark:text-foreground text-sm">
                        About us
                    </Link>
                    <Link href="/contact-us" className="text-white dark:text-foreground text-sm">
                        Contact us
                    </Link>
                    <Link href="/terms" className="text-white dark:text-foreground text-sm">
                        Terms & conditions
                    </Link>
                    <Link href="/privacy-policy" className="text-white dark:text-foreground text-sm">
                        Privacy policy
                    </Link>
                </div>
            </div>
            <div className='text-white dark:text-foreground text-sm text-center py-4 bg-ox-black'>&copy; {new Date().getFullYear()} Oxford PTE</div>
        </footer>
    )
}

export default Footer