import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Head from 'next/head';

const Layout = ({ title, description, children }) => {
    return (
        <div>
            <Head>
                <title>{title ? title + ' | Oxford PTE' : 'Top PTE Resource Supplier In The World'}</title>
                <meta
                    name="description"
                    content={description ? description : 'The world&apos;s top supplier of PTE materials is Oxford PTE. Purchase any PTE resources, including PTE templates, PTE speaking, PTE writing, PTE reading, PTE listening, PTE tips and tricks, PTE exam success, PTE study guides and more. Enjoy easy browsing, safe payment, and success within seven days.'} />
                <meta property="og:title" content={title ? title + ' | Oxford PTE' : 'Top PTE Resource Supplier In The World'} />
                <meta property="og:description" content={description ? description : 'The world&apos;s top supplier of PTE materials is Oxford PTE. Purchase any PTE resources, including PTE templates, PTE speaking, PTE writing, PTE reading, PTE listening, PTE tips and tricks, PTE exam success, PTE study guides and more. Enjoy easy browsing, safe payment, and success within seven days.'} />
            </Head>
            <div className="flex min-h-screen flex-col justify-between">
                <Header />
                <main>{children}</main>
                <Footer />
            </div>
        </div >
    );
}

export default Layout