import React, { useEffect, useState } from "react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, Badge, Image } from "@nextui-org/react";
import { signOut, useSession } from 'next-auth/react';
import { AiOutlineHeart, AiOutlineLock, AiOutlineLogout, AiOutlineShoppingCart, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import Link from 'next/link'
// import Image from 'next/image'
import { ThemeSwitcher } from "../custom/ThemeSwitcher";
import { getTotals } from '@/services/cart';
import { getInitials } from '@/lib/getInitials';
import { RiAccountCircleLine } from "react-icons/ri";
import { IconWrapper } from "@/custom/IconWraper";
import { MdOutlinePrivacyTip, MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import ProfileAvatar from "@/custom/ProfileAvatar";
import { HiOutlineColorSwatch } from "react-icons/hi";


const Header = () => {
    const { push } = useRouter()
    const { data: session } = useSession();

    const { cartProducts, cartTotalQuantity } = useSelector(
        (state) => state.cart
    );
    const [totalCart, setTotalCart] = useState(0);
    const dispatch = useDispatch();

    const logout = async () => {
        Cookies.remove('cart');
        const data = await signOut({ redirect: false, callbackUrl: '/login' });
        push(data?.url)
    };

    useEffect(() => {
        let lastScrollTop = 0;
        let navbar = document.getElementById("navbar");
        window.addEventListener('scroll', function () {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > lastScrollTop) {
                navbar.style.top = "-65px"
            } else {
                navbar.style.top = "0"
            }
            lastScrollTop = scrollTop
        })
    }, []);

    useEffect(() => {
        dispatch(getTotals());
        setTotalCart(cartTotalQuantity);
    }, [cartProducts, cartTotalQuantity, dispatch]);

    return (
        <div className="flex z-40 w-full h-16 overflow-hidden items-center justify-center sticky top-0 inset-x-0 backdrop-blur-lg backdrop-saturate-150 border-b border-gray-500/20 bg-background/70 dark:bg-[#252627]/70" id="navbar">
            <div className="container relative mx-auto h-full flex items-center justify-between px-4">
                <div className="w-28 m-0 cursor-pointer" onClick={()=>push('/')}>
                    <Image
                        radius="none"
                        src="/_oxfordpte.png"
                        alt="Oxford PTE"
                        className="dark:bg-white m-0 p-0 w-full h-full"
                    />
                </div>
                <div className="flex items-center gap-6">
                    <div>
                        <Link href="/cart">
                            <Badge color="danger" content={totalCart} shape="circle">
                                <AiOutlineShoppingCart size={25} className="dark:text-ox-dark-text-gray" />
                            </Badge>
                        </Link>
                    </div>
                    <Dropdown placement="bottom-start">
                        <DropdownTrigger>
                            <div className="rounded-full bg-blue-600 w-10 h-10 flex items-center justify-center cursor-pointer">
                                {!session?.user?.name ? <AiOutlineUser size={24} className="text-white" /> : <span className="text-sm font-medium text-white">{getInitials(session?.user?.name)}</span>}
                            </div>
                        </DropdownTrigger>
                        {
                            session?.user ?
                                <DropdownMenu aria-label="User Actions" variant="flat">
                                    <DropdownItem className="dark:text-foreground !bg-transparent" key="settings">
                                        {/* <div>
                                            <p className="font-medium dark:text-foreground">Signed in as</p>
                                            <p className="font-semibold">{session?.user?.name}</p>
                                        </div> */}
                                        <ProfileAvatar />
                                    </DropdownItem>
                                    {
                                        session?.user?.isAdmin &&
                                        <DropdownItem startContent={
                                            <IconWrapper className="p-0 m-0 bg-green-400/20 text-success">
                                                <MdOutlineAdminPanelSettings size={20} className="p-0 m-0" />
                                            </IconWrapper>
                                        } className="dark:text-foreground" key="admin" color="success">
                                            <Link href="/admin/dashboard" className="text-green-500 font-semibold">
                                                Admin Dashboard
                                            </Link>
                                        </DropdownItem>
                                    }
                                    <DropdownItem className="dark:text-foreground" key="account" startContent={
                                        <IconWrapper className="p-0 m-0 bg-success/10 text-success">
                                            <RiAccountCircleLine size={20} className="p-0 m-0" />
                                        </IconWrapper>
                                    }>
                                        <Link href="/account">
                                            Your account
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem startContent={
                                        <IconWrapper className="p-0 m-0 bg-primary/10 text-primary">
                                            <AiOutlineLock size={20} className="p-0 m-0" />
                                        </IconWrapper>
                                    } className="dark:text-foreground" key="login-security">
                                        <Link href="/login-security">
                                            Login & security
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem startContent={
                                        <IconWrapper className="p-0 m-0 bg-secondary/10 text-secondary">
                                            <MdOutlinePrivacyTip size={20} className="p-0 m-0" />
                                        </IconWrapper>
                                    } className="dark:text-foreground" key="privacy-settings">
                                        <Link href="/privacy-settings">
                                            Privacy settings
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem startContent={
                                        <IconWrapper className="p-0 m-0 bg-warning/10 text-warning">
                                            <AiOutlineVideoCameraAdd size={20} className="p-0 m-0" />
                                        </IconWrapper>
                                    } className="dark:text-foreground" key="My Courses">
                                        <Link href="/my-courses">
                                            My Courses
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem startContent={
                                        <IconWrapper className="p-0 m-0 bg-red-500/10 text-red-500">
                                            <AiOutlineHeart size={20} className="p-0 m-0" />
                                        </IconWrapper>
                                    } className="dark:text-foreground" key="My Wishlists">
                                        <Link href="/my-wishlists">
                                            My Wishlists
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem startContent={
                                        <IconWrapper className="p-0 m-0 bg-warning/10 text-danger">
                                            <AiOutlineLogout size={20} className="p-0 m-0" />
                                        </IconWrapper>
                                    } className="dark:text-foreground" onClick={logout} key="logout">
                                        Log Out
                                    </DropdownItem>
                                    <DropdownItem
                                        key="theme"
                                        className="cursor-default dark:text-foreground"
                                        endContent={<ThemeSwitcher />}
                                        startContent={
                                            <IconWrapper className="p-0 m-0 bg-primary/10 text-blue-500">
                                                <HiOutlineColorSwatch size={20} className="p-0 m-0" />
                                            </IconWrapper>
                                        }
                                    >
                                        Theme
                                    </DropdownItem>
                                </DropdownMenu>
                                :
                                <DropdownMenu aria-label="User Actions" variant="flat">
                                    <DropdownItem key="login">
                                        <Link href="/login">
                                            <Button fullWidth size='sm' className="justify-center text-white dark:text-foreground !shadow-none text-sm !rounded-none bg-ox-blue capitalize flex items-center">
                                                Log in
                                            </Button>
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem key="register">
                                        <Link href="/register">
                                            <Button fullWidth size='sm' className="justify-center text-white dark:text-foreground !shadow-none text-sm !rounded-none bg-ox-black dark:bg-[#252627]/70 capitalize flex items-center">
                                                Sign up
                                            </Button>
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem key="theme" className="mt-4">
                                        <div className="flex justify-between items-center">
                                            <small className="dark:text-foreground">Theme</small>
                                            <ThemeSwitcher />
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                        }
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default Header